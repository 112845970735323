import Image from "next/image";
import style from "./style.module.css";
import whatsapp from "../../assets/images/whatsapp.png";
import calling from "../../assets/images/calling.gif";
import { useRouter } from 'next/router';

const Whatsapp=()=>{
    const router = useRouter();
    const hiddenButtonUrl = '/upsc-digital-lead';
    const shouldShowButton = router.pathname !== hiddenButtonUrl;
    const buttonStyle = {
        display: 'block',
      };
    
      const hiddenStyle = {
        display: 'none',
      };
     
    return(
        <>
        <a className={`${style.linkfont}`} href="tel: 9003190030" target="blank"> <div  className={`${style.callbutton} pb-2`} id={style.fixedcallbutton}><Image src={calling} width='50' height='50'  className={`${style.callimg}`}></Image> 90031 90030 </div></a>
       <div style={ shouldShowButton ? buttonStyle  : hiddenStyle }>
        <a className={`${style.linkfont}`} href="https://api.whatsapp.com/send/?phone=917010455283&text=Hi,%20I%20am%20looking%20for%20admissions&app_absent=0" target="blank"><button className={`${style.button}`} id={style.fixedbutton}><Image src={whatsapp} className="img-fluid align-middle me-2" alt="Whatsapp" />How can we help you?</button></a>
        </div>

        {/* <div className="d-block d-md-none">
        <a className={`${style.linkfont}`} href="https://api.whatsapp.com/send?phone=919003190030&text=Hello!" target="blank"><button className={`${style.button}`} id={style.fixedbutton}><Image src={whatsapp} className="img-fluid align-middle me-2" alt="Whatsapp" />How can we help you?</button></a>
        </div> */}
        </>
    )
}
export default Whatsapp;